// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["draftCheckbox", "submit", "priceFieldWrapper", "priceField", "freeField"]

  connect() {
    this.updateSubmitButtonText()
    this.updatePriceFieldWrapperVisibility()
  }

  updateSubmitButtonText() {
    if (this.draftCheckboxTarget.checked) {
      this.submitTarget.value = "Save Draft"
    }
  }

  updatePriceFieldWrapperVisibility() {
    if (this.freeFieldTarget.checked) {
      this.priceFieldWrapperTarget.classList.add('d-none')
    }
  }

  toggleDraft(event) {
    if (event.target.checked) {
      this.submitTarget.value = "Save Draft"
    } else {
      this.submitTarget.value = "Publish"
    }
  }

  toggleFree(event) {
    if (event.target.checked) {
      this.priceFieldTarget.value = 0
      this.priceFieldWrapperTarget.classList.add('d-none')
    } else {
      this.priceFieldWrapperTarget.classList.remove('d-none')
    }
  }
}
