// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["startsAt", "endsAt"]

  connect() {}

  updateEndsAt() {
    const startsAtValue = this.startsAtTarget.value
    const endsAtValue = this.endsAtTarget.value

    if (startsAtValue && endsAtValue) {
      const startsAtDate = new Date(startsAtValue)

      // Set endsAt to one hour after startsAt
      const newEndsAtDate = new Date(startsAtDate.getTime() + 60 * 60 * 1000)
      this.endsAtTarget.value = this.formatDateTimeLocal(newEndsAtDate)
    }
  }

  formatDateTimeLocal(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    return `${year}-${month}-${day}T${hours}:${minutes}`
  }

  handleStartsAtChange() {
    this.updateEndsAt()
  }
}
