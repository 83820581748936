import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    try {
      if (typeof(google) != undefined){
        this.initMap()
      }
    } catch(err) {}
  }

  initMap() {
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged(){
    // Place changed! Leave here for now.
  }
}
