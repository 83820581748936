import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["map", "sidebar"]
  static values = { places: Array, nearbyGroups: Array, centerLocation: Object, iconUrl: String }

  connect() {
    try {
      if (typeof(google) != undefined) {
        // Let's wait 0.5 second for the Google Maps API to load
        setTimeout(() => {
          this.initializeMap()
        }, 500)
      }
    } catch (err) {
      console.error("Google Maps failed to load", err)
    }
  }

  initializeMap() {
    this.map()
    this.addMarkers() // Show places on the map
    this.centerMapOnLoad() // Center map on load based on center location
    this.updateSidebarOnLoad() // Load the nearby groups into the sidebar
  }

  map() {
    if (this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(55.3781, -3.4360), // Default center (UK)
        zoom: 6,
        maxZoom: 13,
        minZoom: 3,
        mapTypeControl: false,
        streetViewControl: false,
        keyboardShortcuts: false,
        fullscreenControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
      })
    }
    console.log("Map connected")
    return this._map
  }

  addMarkers() {
    this.placesValue.forEach(place => {
      let marker = new google.maps.Marker({
        position: new google.maps.LatLng(place.latitude, place.longitude),
        map: this._map,
        title: `${place.groups_count} groups in ${place.name}`,
        icon: {
          url: this.iconUrlValue,
          scaledSize: new google.maps.Size(30, 30) // Resize the icon if needed
        }
      })

      marker.addListener('click', () => {
        this.centerMapOnMarker(marker)
        this.queryNearbyGroups(place.latitude, place.longitude, place.name)
      })
    })
  }

  centerMapOnMarker(marker) {
    this._map.setCenter(marker.getPosition())
    this._map.setZoom(7)
  }

  centerMapOnLoad() {
    if (this.centerLocationValue) {
      const { latitude, longitude } = this.centerLocationValue
      this._map.setCenter(new google.maps.LatLng(latitude, longitude))
      this._map.setZoom(7)
    }
  }

  updateSidebarOnLoad() {
    if (this.nearbyGroupsValue && this.centerLocationValue.name) {
      this.updateSidebar(this.nearbyGroupsValue, this.centerLocationValue.name)
    }
  }

  queryNearbyGroups(latitude, longitude, placeName) {
    const url = `/groups?place=${encodeURIComponent(placeName)}`

    fetch(url, {
      headers: { 'Accept': 'application/json' }
    })
        .then(response => response.json())
        .then(data => {
          this.updateSidebar(data.nearby_groups, placeName)
        })
        .catch(error => console.error("Error fetching nearby groups:", error))

    history.pushState(null, null, url)
  }

  updateSidebar(nearbyGroups, placeName) {

    let listTitleElement = document.getElementById("group-list-title")
    let groupListElement = document.getElementById("group-list")
    groupListElement.classList.remove("d-none")

    if (groupListElement) {
      let groupList = nearbyGroups.map(group => `
        <div class="ms-3">
          <span class="d-flex align-items-center">
            <a class="fw-bold mb-0 text-white" href="/groups/${group.slug}" target="_blank">${group.name}</a>
            <i class="fa fa-external-link-alt mini ms-2"></i>
          </span>
          <p class="mini">${group.summary}</p>
        </div>
      `).join('')

      const groupWord = nearbyGroups.length === 1 ? 'group' : 'groups';
      listTitleElement.innerHTML = `${nearbyGroups.length} ${groupWord} near <span class="text-white fw-bold text-decoration-underline">${placeName}</span>`;
      groupListElement.innerHTML = `${groupList}`;
    } else {
      console.error("Group list element not found")
    }
  }
}
