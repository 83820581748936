import { Controller } from "stimulus"

const londonLatLng = [51.50701344419214, -0.12809013193865162]
const darkModeStyles = [
  { elementType: 'geometry', stylers: [{ color: '#171717' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#171717' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#00bfc9' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#00bfc9' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }]
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#000000' }]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#000000' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }]
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#00bfc9' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#1d283b' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#1d283b' }]
  }
];

const defaultIconColor = '#d94747';
const selectedIconColor = '#0d6efd';

let markers = {};

export default class extends Controller {
  static targets = ["field", "indexMap"]

  connect() {
    try {
      if (typeof(google) != undefined){
        this.initializeMap()
      }
    } catch(err) {}
  }

  initializeMap() {
    let events = JSON.parse(this.data.get("events"))
    let center = JSON.parse(this.data.get("coordinates"))
    this.map(center)
    this.loadMarkers(events)
  }

  map(center) {
    if(this._map == undefined) {
      const centerCoords = center || londonLatLng  // Default to London
      this._map = new google.maps.Map(this.indexMapTarget, {
        center: new google.maps.LatLng(centerCoords[0], centerCoords[1]),
        zoom: 13,
        minZoom: 3,
        maxZoom: 18,
        styles: darkModeStyles,
        mapTypeControl: false
      })
    }
    return this._map
  }

  customIcon(color) {
    return {
      path: 'M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z',
      fillColor: color,
      strokeColor: color,
      fillOpacity: 1,
      strokeWeight: 2,
      scale: 0.07,
      anchor: new google.maps.Point(200, 500)
    };
  }


  geolocate() {
    if (!navigator.geolocation) {
      this.linkTarget.textContent = "Geolocation is not supported in this browser."
    } else {
      navigator.geolocation.getCurrentPosition(this.setMapCenter.bind(this))
    }
  }

  setMapCenter(position) {
    this._map.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
  }

  loadMarkers(events) {
    let map = this.map();
    let infoWindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(0, -40)
    });

    events.forEach(event => {
      const marker = new google.maps.Marker({
        map: map,
        anchorPoint: new google.maps.Point(0, 0),
        icon: this.customIcon(defaultIconColor)
      })

      let markerLocation = {
        lat: parseFloat(event.latitude),
        lng: parseFloat(event.longitude)
      }

      marker.setPosition(markerLocation);
      marker.setVisible(true);
      markers[event.id] = marker;

      marker.addListener('mouseover', () => this.highlightEventRow(event.id));
      marker.addListener('mouseout', () => this.resetEventRow(event.id));

      let eventDate = new Date(event.starts_at).toUTCString();

      marker.addListener('click', function() {
        let infoContent = `<div class="map-info-window"><p class="fw-bold mb-1">${event.title}</p><p class="mini mb-1">${eventDate}</p><a href="${event.event_url}" class="fw-bold float-end">View</a></div>`;  // Adjust the content as needed
        infoWindow.setContent(infoContent);
        infoWindow.open(map, marker);
      });

      return marker;
    })
  }

  highlightMarker(event) {
    const eventId = event.currentTarget.dataset.mapsId;
    const marker = markers[eventId];

    if (marker && marker.getMap()) {
      let currentLatLng = marker.getPosition();
      let map = marker.getMap();

      let point = map.getProjection().fromLatLngToPoint(currentLatLng);

      let pixelOffset = 20 / Math.pow(2, map.getZoom());
      point.y -= pixelOffset;

      let newLatLng = map.getProjection().fromPointToLatLng(point);

      marker.setPosition(newLatLng);
      marker.setIcon(this.customIcon(selectedIconColor));
    }

  }

  resetMarker(event) {
    const eventId = event.currentTarget.dataset.mapsId;
    const marker = markers[eventId];
    if (marker && marker.getMap()) {
      let currentLatLng = marker.getPosition();
      let map = marker.getMap();

      let point = map.getProjection().fromLatLngToPoint(currentLatLng);

      let pixelOffset = 20 / Math.pow(2, map.getZoom());
      point.y += pixelOffset;

      let newLatLng = map.getProjection().fromPointToLatLng(point);

      marker.setPosition(newLatLng);
      marker.setIcon(this.customIcon(defaultIconColor));
    }
  }

  highlightEventRow(eventId) {
    const eventRow = document.getElementById(`event_${eventId}`);
    if (eventRow) {
      eventRow.classList.add("active");
    }
  }

  resetEventRow(eventId) {
    const eventRow = document.getElementById(`event_${eventId}`);
    if (eventRow) {
      eventRow.classList.remove("active");
    }
  }

}
