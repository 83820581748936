// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    const row = this.element.children[0]
    $(row).on("click", function() {
      if ($(this).parent(".event-toggle").hasClass("active")) {
        $(this).parent(".event-toggle").removeClass("active");
        $(this).parent(".event-toggle")
            .children(".description")
            .slideUp(200);
      } else {
        $(this).parent(".event-toggle")
        $(".event-toggle").removeClass("active");
        $(this).parent(".event-toggle").addClass("active");

        $(".description").slideUp(200);
        $(this).parent(".event-toggle")
            .children(".description")
            .slideDown(200);
      }
    });
  }
}
