// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["field", "showMap"]

  connect() {
    try {
      if (typeof(google) != undefined){
        this.initializeMap()
      }
    } catch(err) {}
  }

  initializeMap() {
    let event = JSON.parse(this.data.get("event"))
    this.map(event)
    this.loadMarkers(event)
  }

  map(event) {
    if(this._map == undefined) {
      this._map = new google.maps.Map(this.showMapTarget, {
        center: new google.maps.LatLng(event.latitude, event.longitude),
        zoom: 15,
        mapTypeControl: false,
        streetViewControl: false,
        keyboardShortcuts: false,
        fullscreenControl: false,
        zoomControl: false,
        styles: [
          { elementType: 'geometry', stylers: [{ color: '#171717' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#171717' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#00bfc9' }]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#00bfc9' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }]
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#000000' }]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#000000' }]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }]
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }]
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#00bfc9' }]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#1d283b' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#1d283b' }]
          }
        ]
      })
    }
    return this._map
  }

  loadMarkers(event) {
    this._marker = new google.maps.Marker({
      map: this.map(),
      anchorPoint: new google.maps.Point(0, 0)
    })
    let markerLocation = {
      lat: parseFloat(event.latitude),
      lng: parseFloat(event.longitude)
    }
    this._marker.setPosition(markerLocation)
    this._marker.setVisible(true)
    let circle = new google.maps.Circle({
      map: this.map(),
      radius: 400,
      fillColor: '#858dff',
      strokeWeight: 0
    });
    circle.bindTo('center', this._marker, 'position');
    return this._marker
  }
}
