// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
// require("gmaps4rails").start()

window.Rails = Rails

import "@hotwired/turbo-rails"
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import './navToggle'
import LocalTime from "local-time"
LocalTime.start()

document.addEventListener("turbolinks:load", () => {
  // This enables the Bootstrap toasts
  var toastElList = [].slice.call(document.querySelectorAll('.toast'))
  var toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl, option)
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
  $('.select2-multiple').select2({
    maximumSelectionLength: 3
  })
})

document.addEventListener("turbolinks:load", () => {
  // Reinitialize tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    // Destroy any existing tooltips to avoid duplicates
    var existingTooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
    if (existingTooltip) {
      existingTooltip.dispose();
    }
    // Initialize a new tooltip
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
});


window.dispatchMapsEvent = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

require("trix")
require("@rails/actiontext")
import "controllers"
