import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "output"]

  connect() {
    this.outputTarget.textContent = `${this.inputTarget.value} km`;
  }

  update() {
    this.outputTarget.textContent = `${this.inputTarget.value} km`;
  }
}
